import { EMPTY_STATE_ILLUSTRATION_TYPES, LeftButton, MIDEmptyState, TableEmptyStateWrapper } from '@mid-react-common/common';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { releasesPageIds } from 'tests/helpers/dataTestIds';
import text from '../../global/text.json';
import {
  DataGridPremiumWrapper,
  ProductSelectionList,
  ProductSelectionWrapper,
  ReleasesControlsWrapper,
  ReleasesWrapper,
} from './Releases.styles';
import { getColumns } from './Releases.utils';
import ReleasesDetails from './ReleasesDetails/ReleasesDetails';
import ReleaseStatusUpdate, { ReleaseStatusUpdateButtonVariant } from './ReleseStatusUpdate/ReleaseStatusUpdate';
import useReleases from './useReleases';
import { useFlags } from 'launchdarkly-react-client-sdk';

const releasesText = text.releases;

const Releases: React.FC = () => {
  const { enableStaticContent } = useFlags();
  const {
    gridApiRef,
    tableLoading,
    productsLoading,
    productReleases,
    productListFiltered,
    releaseTableData,
    selectedProduct,
    detailsVisibility,
    selectedReleasesIds,
    handleFilter,
    handleProductSelection,
    handleDetailsVisibilityClick,
    onReleaseSelection,
    handleCloseDetails,
    refetchProductReleases,
  } = useReleases();

  const renderEmptyState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState title={releasesText.noProductSelected} />
    </TableEmptyStateWrapper>
  );
  const renderFailureState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR} title={releasesText.releasesError} />
    </TableEmptyStateWrapper>
  );

  return (
    <>
      <ProductSelectionWrapper>
        <TextField
          data-testid={releasesPageIds.productsSearch}
          hiddenLabel
          fullWidth
          variant="filled"
          placeholder={releasesText.productSearchPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onInput={handleFilter}
        />
        <ProductSelectionList>
          {productsLoading && <LinearProgress />}
          <List data-testid={releasesPageIds.productsList}>
            {productListFiltered.map((currentProduct) => (
              <ListItemButton
                key={currentProduct.contentId}
                selected={selectedProduct === currentProduct}
                onClick={() => handleProductSelection(currentProduct)}
              >
                <ListItemText>{currentProduct.name}</ListItemText>
                {selectedProduct === currentProduct && (
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                )}
              </ListItemButton>
            ))}
          </List>
        </ProductSelectionList>
      </ProductSelectionWrapper>
      <Divider orientation="vertical" />
      <ReleasesWrapper>
        <ReleasesControlsWrapper>
          {selectedReleasesIds.length === 1 && selectedProduct && (
            <ReleaseStatusUpdate
              productReleases={productReleases}
              releaseNumber={Number(selectedReleasesIds[0])}
              refetchProductReleases={refetchProductReleases}
              buttonVariant={ReleaseStatusUpdateButtonVariant.button}
            />
          )}
          <LeftButton
            disabled={!selectedReleasesIds.length}
            variant="outlined"
            onClick={handleDetailsVisibilityClick}
            data-testid={releasesPageIds.buttonShowHideDetails}
          >
            {detailsVisibility ? releasesText.hideDetails : releasesText.showDetails}
          </LeftButton>
        </ReleasesControlsWrapper>
        <DataGridPremiumWrapper
          disableVirtualization
          checkboxSelection
          apiRef={gridApiRef}
          loading={tableLoading}
          columns={getColumns({ enableStaticContent })}
          rows={releaseTableData}
          onRowSelectionModelChange={onReleaseSelection}
          slots={{
            noRowsOverlay: (productReleases && renderEmptyState) || renderFailureState,
          }}
        />
      </ReleasesWrapper>
      <Divider orientation="vertical" />
      <ReleasesDetails
        productReleases={productReleases}
        selectedReleasesIds={selectedReleasesIds}
        selectedProduct={selectedProduct}
        detailsVisibility={detailsVisibility}
        handleCloseDetails={handleCloseDetails}
        refetchProductReleases={refetchProductReleases}
      />
    </>
  );
};

export default Releases;
